import React from 'react'

import styles from './style.module.scss'

import NotFound from '../../images/not-found.svg'

export default () => {
    return (
        <div className={styles.notFoundContainer}>
            <div className="wrapper">
                <div className="row">
                    <div className="col-xs-12">
                        <h1>NOT FOUND</h1>
                        <p>
                            You just hit a route that doesn&#39;t exist... the
                            sadness.
                        </p>
                        <NotFound className={styles.image} />
                    </div>
                </div>
            </div>
        </div>
    )
}
